<!--
 * @Author: xr
 * @Date: 2022-02-17 10:19:07
 * @LastEditors: xr
 * @LastEditTime: 2022-03-11 11:09:59
 * @FilePath: \postStation-office\src\views\detail.vue
-->
<template>
  <div class="main">
    <div class="main_box">
        <a-row>
          <a-col :span="7">
            <div class="right-side-assembly">
              <find-demand class="find-item" title="找项目" :technicalProjectContent="technicalProjectContent" />
              <find-demand class="find-demand" title="找需求" :technicalProjectContent="technicalProjectContent"/>
            </div>
          </a-col>
          <a-col :span="17" class="description">
            <a-row class="sub-component-background-color" style="min-height:79vh;padding:0px 10px;">
              <div class="info-title">{{ details.type_dictText }}技术信息</div>
              <!-- 项目信息 -->
              <a-descriptions layout="horizontal" bordered size="small">
                <a-descriptions-item span="3">
                  <span slot="label">{{ details.type_dictText }}名称</span>
                  {{ details.productName }}
                </a-descriptions-item>
                <a-descriptions-item label="应用领域" span="2">
                  {{ details.applicationArea_dictText }}
                </a-descriptions-item>
                <a-descriptions-item label="合作方式">
                  {{ details.cooperationMode_dictText }}
                </a-descriptions-item>
                <a-descriptions-item label="所属地域" span="2">
                  {{ details.region }}
                </a-descriptions-item>
                <a-descriptions-item>
                  <span slot="label">{{ details.type_dictText }}权属</span>
                  <span :class="details.file?'text-line':''" @click="download(details.file)">{{ details.projectOwnership }}</span>
                </a-descriptions-item>
                <a-descriptions-item label="意向价格">
                  {{ details.price }}
                </a-descriptions-item>
              </a-descriptions>
              <!-- 技术信息 -->
              <a-descriptions style="margin-top:20px;" bordered size="small">
                <a-descriptions-item label="信息有效期" span="3">
                  {{ details.informationValidityPeriodStart? (details.informationValidityPeriodStart + ' 至 ' +details.informationValidityPeriodEnd) : ''}}
                </a-descriptions-item>
                <a-descriptions-item label="需求\供应方式" span="3">
                  {{ details.demand }}
                </a-descriptions-item>
                <a-descriptions-item span="3">
                  <span slot="label">{{ details.type_dictText }}简介</span>
                  {{ details.projectIntroduction }}
                </a-descriptions-item>
                <a-descriptions-item label="意向方资格条件" span="3">
                  {{ details.conditions }}
                </a-descriptions-item>
                <a-descriptions-item label="其他" span="3">
                  {{ details.other }}
                </a-descriptions-item>
                <a-descriptions-item label="获得资助情况">
                  {{ details.support_dictText }}
                </a-descriptions-item>
              </a-descriptions>
            </a-row>
          </a-col>
        </a-row>
    </div>
  </div>
</template>
<script>
import FindDemand from '@comp/findDemand'
import { getDict } from '@/api/index'
export default {

  name: 'Detail',
  components: {
    FindDemand
  },
  data() {
    return {
      technicalProjectContent: [],
      details: []
    }
  },
  methods: {
    /**
     * @description: 资格下载
     * @param {*} file
     */
    download(file) {
      if (file) {
        this.$message.success('下载中！')
        const a = document.createElement('a')
        a.href = file
        a.download = file
        a.click()
        window.URL.revokeObjectURL(file)
      } else {
        this.$message.error('获取文件失败！')
      }
    }
  },
  created() {
    // 找项目找需求
    getDict('application_field').then(res => {
      this.technicalProjectContent = res.result
    })
  },
  activated() {
    // 刷新
    if (JSON.stringify(this.$route.params) !== '{}') {
      // 接收传过来的值
      this.details = this.$route.params
      sessionStorage.setItem('project', JSON.stringify(this.$route.params))
    } else {
      this.details = JSON.parse(sessionStorage.getItem('project'))
    }
  }
}
</script>
<style lang="scss" scoped>
$border: #85C0EE;
.description {
  min-height: 80vh;
  padding: 0px 10px;
  .info-title{
    padding-top: 10px;
    text-align: center;
    font-size: 20px;
    margin-bottom: 5px;
    font-family: "宋体";
    font-weight: bold;
  }
  .text-line{
    text-decoration:underline;
    cursor: pointer;
    color: #047dd2;
  }
  ::v-deep .ant-descriptions-item-content{
    border-right:1px solid  $border;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-item-label{
    border-right:1px solid  $border;
    background: none;
    font-weight: bold;
  }
  ::v-deep .ant-descriptions-bordered .ant-descriptions-row{
    border-bottom: 1px solid $border;
  }
  ::v-deep .ant-descriptions-view{
    border-top: 4px solid #047dd2;
    border-right: 0px;
    border-bottom: 0px;
    border-left: 1px solid $border;
  }
}
@import "../assets/css/newsInformation.css";
</style>
