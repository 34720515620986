<!--
 * @Author: xr
 * @Date: 2022-02-18 13:31:34
 * @LastEditors: xr
 * @LastEditTime: 2022-03-07 11:31:24
 * @FilePath: \postStation-office\src\components\findDemand.vue
-->
<!--找需求组件-->
<template>
  <div class="lookFor sub-component-background-color">
    <div class="head">
      <div class="headerContent">
        <div class="headerContent-title">
          {{ title }}
        </div>
      </div>
    </div>
    <div class="content">
      <span class="contentHeader">[ {{ transferInformation }} ]</span>
      <a-empty v-if="technicalProjectContent.length===0" class="empty-data"/>
      <span class="area-item" v-else v-for="(item,i) in technicalProjectContent" :key="item.id" @click="jump(item.value)"> {{item.title}}
        <span v-show="i<technicalProjectContent.length-1">|</span>
        </span>
    </div>
  </div>
</template>

<script>
import { Station } from '@/mixins/station'
export default {
  name: 'FindDemand',
  mixins: [Station],
  props: ['title', 'technicalProjectContent'],
  data() {
    return {
      transferInformation: '应用领域',
      stationId: ''
    }
  },
  methods: {
    jump(value) {
      this.getStationId()
      if (this.title === '找项目') {
        if (this.stationId) {
          window.open(window.location.origin + '/resource-list' + '?mainResearchAreas=' + value + '&stationId=' + this.stationId, '_self')
        } else {
          window.open(window.location.origin + '/resource-list' + '?mainResearchAreas=' + value, '_self')
        }
      } else {
        if (this.stationId) {
          window.open(window.location.origin + '/result' + '?mainResearchAreas=' + value + '&stationId=' + this.stationId, '_self')
        } else {
          window.open(window.location.origin + '/result' + '?mainResearchAreas=' + value, '_self')
        }
      }
    }
  },
  created() { }
}
</script>

<style scoped>
@import "~@assets/css/list_pic.css";
.area-item:hover{
  cursor: pointer;
  color: #FF0000;
}
</style>
